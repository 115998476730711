exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/aboutUs.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-admin-panel-jsx": () => import("./../../../src/pages/adminPanel.jsx" /* webpackChunkName: "component---src-pages-admin-panel-jsx" */),
  "component---src-pages-admin-project-jsx": () => import("./../../../src/pages/adminProject.jsx" /* webpackChunkName: "component---src-pages-admin-project-jsx" */),
  "component---src-pages-career-jsx": () => import("./../../../src/pages/career.jsx" /* webpackChunkName: "component---src-pages-career-jsx" */),
  "component---src-pages-company-jsx": () => import("./../../../src/pages/company.jsx" /* webpackChunkName: "component---src-pages-company-jsx" */),
  "component---src-pages-contactus-jsx": () => import("./../../../src/pages/contactus.jsx" /* webpackChunkName: "component---src-pages-contactus-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-iptv-jsx": () => import("./../../../src/pages/iptv.jsx" /* webpackChunkName: "component---src-pages-iptv-jsx" */),
  "component---src-pages-jobapply-jsx": () => import("./../../../src/pages/jobapply.jsx" /* webpackChunkName: "component---src-pages-jobapply-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-projects-jsx": () => import("./../../../src/pages/projects.jsx" /* webpackChunkName: "component---src-pages-projects-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */)
}

